import React from 'react';
import { navigate } from 'gatsby';
import { Auth0Provider } from '@auth0/auth0-react';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import theme from './config/theme';

if (process.env.NODE_ENV === "development") {
  // import('/mocks').then((mocks) => {
  //   return mocks.default();
  // });
}

const onRedirectCallback = (appState) => {
 navigate(appState?.returnTo || '/', { replace: true });
};

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Auth0Provider
        audience={process.env.GATSBY_AUTH0_AUDIENCE}
        domain={process.env.GATSBY_AUTH0_DOMAIN}
        clientId={process.env.GATSBY_AUTH0_CLIENTID}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        {element}
      </Auth0Provider>
    </ThemeProvider>
  );
 };