import './../static/fonts/aeonik/400.css';
import './../static/fonts/aeonik/500.css';

import { alpha, createTheme } from '@mui/material/styles';
import { blue, common, green, red, yellow } from '@mui/material/colors';
import { linkClasses } from '@mui/material/Link';

const AeonikRegularFontFamily = {
  fontFamily: [
    `'Aeonik'`,
    `'Helvetica Neue'`,
    `-apple-system`,
    `system-ui`,
    `BlinkMacSystemFont`,
    `sans-serif`,
  ].join(`,`),
};

const mainFontFamily = AeonikRegularFontFamily;
const secondaryFontFamily = AeonikRegularFontFamily;

// Create the base theme so we can use the generated theme in the extensions.
let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1920,
    },
  },
  palette: {
    background: {
      default: common.white, // #f9faf6
    },
    primary: {
      main: common.black,
    },
    secondary: {
      main: blue.A700,
    },
    blue,
    green,
    red,
    yellow,
  },
  shape: {
    borderRadius: 6,
  },
  typography: {
    ...mainFontFamily,
    fontSize: 16,
    htmlFontSize: 18,
  },
});

// Extend the base theme with typography overrides.
theme = createTheme(theme, {
  typography: {
    ...mainFontFamily,
    h1: {
      ...mainFontFamily,
      fontSize: `2.75rem`,
      fontWeight: 500,
      letterSpacing: -1,
      lineHeight: 1.2,
    },
    h2: {
      ...mainFontFamily,
      fontSize: `2.25rem`,
      fontWeight: 500,
      letterSpacing: -1,
      lineHeight: 1.3,
    },
    h3: {
      ...mainFontFamily,
      fontSize: `1.875rem`,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.4,
    },
    h4: {
      ...mainFontFamily,
      fontSize: `1.563rem`,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.5,
    },
    h5: {
      ...mainFontFamily,
      fontSize: `1.313rem`,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.6,
    },
    h6: {
      ...mainFontFamily,
      fontSize: `1.25rem`,
      fontWeight: 500,
      letterSpacing: -0.5,
      lineHeight: 1.7,
    },
    body1: {
      ...secondaryFontFamily,
      fontSize: `1rem`,
      lineHeight: `1.45`,
    },
    body2: {
      ...secondaryFontFamily,
      fontSize: `1rem`,
      lineHeight: `1.45`,
    },
    subtitle1: {
      ...secondaryFontFamily,
      color: theme.palette.grey[800],
      fontSize: `1.125rem`,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    subtitle2: {
      ...secondaryFontFamily,
    },
    button: {
      ...secondaryFontFamily,
    },
    caption: {
      ...secondaryFontFamily,
    },
    overline: {
      ...secondaryFontFamily,
    },
  },
});

// Extend the base theme with component overrides.
theme = createTheme(theme, {
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: `.875rem`,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: `1rem`,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          ...mainFontFamily,
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: `.95rem`,
          margin: theme.spacing(0, 0, 1.5, 0),
        },
        separator: {
          color: theme.palette.grey[300],
        },
        li: {
          [`& > p`]: {
            color: theme.palette.grey[900],
            fontSize: `.95rem`,
          },
          [`& > a.${linkClasses.root}`]: {
            color: theme.palette.grey[500],
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...secondaryFontFamily,
          borderRadius: theme.shape.borderRadius,
          boxShadow: `none`,
          fontWeight: 500,
          textTransform: `none`,
          [`&:hover`]: {
            boxShadow: `none`,
          },
          [`&:active`]: {
            boxShadow: `none`,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: blue.A700,
          fontWeight: `500`,
          textDecoration: `none`,
          [`:hover`]: {
            color: blue.A400,
            textDecoration: `underline`,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: `1rem`,
          borderBottomColor: theme.palette.grey.A200,
          [`:first-child`]: {
            paddingLeft: 0,
          },
          [`:last-child`]: {
            paddingRight: 0,
          },
        },
        head: {
          color: theme.palette.grey.A700,
          borderBottomColor: theme.palette.grey.A200,
          fontSize: `1rem`,
          fontWeight: 400,
        },
        sizeMedium: {
          padding: theme.spacing(1.25),
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root': {
            borderRadius: 4,
            backgroundColor:
              theme.palette.mode === `light`
                ? theme.palette.grey.A100
                : theme.palette.grey.A700,
            border: `1px solid`,
            borderColor:
              theme.palette.mode === `light`
                ? theme.palette.grey[`300`]
                : theme.palette.grey[`900`],
            overflow: `hidden`,
            transition: theme.transitions.create([
              `border-color`,
              `background-color`,
              `box-shadow`,
            ]),
            '&:hover': {
              backgroundColor: `transparent`,
            },
            '&.Mui-focused': {
              backgroundColor: `transparent`,
              boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
              borderColor: theme.palette.primary.main,
            },
          },
        },
      },
    },
  },
});

export default theme;
